module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-122652265-1","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"brianatiyeh.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brian Atiyeh","short_name":"Brian Atiyeh","start_url":"/","background_color":"#353535","theme_color":"#353535","display":"standalone","icon":"content/assets/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
